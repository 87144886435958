import React, { useState, useRef } from 'react';
import callIcon from '../assets/images/call.svg';

const Products = ({ data }) => {
    
    // manage_status_kx
    const groupKx = (item) => {
        return item.golf_resort.manage_status === 'kx'
    }
    const BubbleKx = (item) => {
        return groupKx(item) ? "chat-table-item-kx" : "chat-table-item";
    }

    // 가격 format
    const formatPrice = (price) => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    
    // 시간 format
    const formatDate = (date) => {
        return date.toString().substring(0,16).replace('T',' ');
    }

    // 전화 걸기
    const handleCall = (phoneNumber) => {
        window.location.href = `tel:${phoneNumber}`;
    };

    // 더보기 버튼
    const [showAll, setShowAll] = useState(false);
    const displayData = showAll ? data : data.slice(0,3);
    const timestampRef = useRef(Date.now());
    // 더보기 버튼의 위치를 하단으로 고정
    const handleToggleView = (e) => {
        const chatMessages = document.querySelector('.chat-messages');
        const timestamp = e.target.id.split('-').pop();
        
        setShowAll(!showAll);
        
        setTimeout(() => {
            const scrollPoint = document.querySelector(`#scroll-point-${timestamp}`);
            
            if (chatMessages && scrollPoint) {
                const scrollPosition = scrollPoint.offsetTop - chatMessages.clientHeight;
                chatMessages.scrollTo({
                    top: scrollPosition,
                    behavior: 'smooth'
                });
            }
        }, 100);
    }


    return (
        <>
        {displayData.map((item, index) => (
            <div key={index} className="chat-table-container">
                    <div className={BubbleKx(item)}>
                        <div className="product-main">
                            <div className="row">
                                <span className="products-name">{item.golf_resort.name}</span>
                                <span className="products-price">{formatPrice(item.greenFee)}원</span>
                            </div>
                            {item.phone_numbers !== "" && (
                                <div 
                                    className="products-callBox"
                                    onClick={() => handleCall(item.phone_numbers)}
                                >
                                    <img src={callIcon} alt="전화 아이콘" />
                                    <span>통화</span>
                                </div>
                            )}
                        </div>
                        <div className="product-info">
                            <div className="info-row">
                                <span className="label">날짜</span>
                                <span className="value">{formatDate(item.dates)}</span>
                            </div>
                            <div className="info-row">
                                <span className="label">주소</span>
                                <span className="value">{item.golf_resort.address}</span>
                            </div>
                            <div className="info-row">
                                <span className="label">내용</span>
                                <span className="value">{item.contents}</span>
                            </div>
                        </div>
                    </div>
            </div>
        ))}
        {data.length > 3 && (
            <>
                <button
                    id={`show-more-${timestampRef.current}`}
                    onClick={handleToggleView}
                    className='show-more-button'
                > 
                    {showAll ? '접기' : '더보기'}
                </button>
                <div
                    id={`scroll-point-${timestampRef.current}`}
                    className='scroll-point'
                    style={{height:'1px', visibility:'hidden'}}
                />
            </>
        )}
        </>
    );
};

export default Products;