import { customerMessage, sendScenarioMessage } from "../Repository/Message";
import { ScenarioAPI } from "../services/ScenarioAPI"

const useScenario = (
    setKeyword,
    setMetaData,
    setPackageData,
    setMessages,
) => {
    
    const keywordLogic = async (text, metaData) => {
        const response = await ScenarioAPI.keywordScenario(text, metaData);
        const msgData = response.data.data;

        // chat message
        const botMessage = await sendScenarioMessage(response);
        setMessages((prev) => [...prev, botMessage]);

        // status 변경
        const checkList = !!msgData.next_scenario_meta_data?.package_id_list;
        if (checkList === false){
            setKeyword(msgData.next_scenario_code);
            setMetaData(msgData.next_scenario_meta_data);
        } else {
            setKeyword(msgData.next_scenario_code);
            setMetaData(msgData.next_scenario_meta_data);
            setPackageData(msgData.next_scenario_meta_data.package_id_list);
        };
    };

    const showPackageLogic = async (text, packageData) => {
        const response = await ScenarioAPI.showPackageScenario(text, packageData);

        // Products 유효성 검사
        const message = await sendScenarioMessage(response);
        const products = message.products
        
        if (products && products.length > 0){
            setMessages((prev) => [...prev, message]);
        } else {
            // products 없음
            const emptyProducts = await customerMessage(
                '검색하신 상품이 존재하지 않습니다.',
                'chatbot'
            );
            setMessages((prev) => [...prev, emptyProducts]);
        }

        // 추가 안내 메시지
        const additionalMessage = await customerMessage(
            '찾으시는 예약 상품이 더 있으신가요?',
            'chatbot'
        );
        setMessages((prev) => [...prev, additionalMessage]);

        // status 변경
        setKeyword('keyword');
        setMetaData(null);
        setPackageData([]);
    }

    const userQueryLogic = async (text, packageData, firstStep) => {
        const response = await ScenarioAPI.userQueryToAnswer(text, packageData);
        const msgData = response.data.data;

        const botMessage = await sendScenarioMessage(response);
        setMessages((prev) => [...prev, botMessage]);

        // status 변경
        const checkList = !!msgData.next_scenario_meta_data?.package_id_list;
        if (checkList === false){
            setMetaData(msgData.next_scenario_meta_data);
        } else {
            setMetaData(msgData.next_scenario_meta_data);
            setPackageData(msgData.next_scenario_meta_data.package_id_list);
        };
        
        
        const currentPackageData = msgData.next_scenario_meta_data.package_id_list;
        if (!currentPackageData || currentPackageData.length === 0) {
            // 패키지 데이터가 없는 경우
            const additionalMessage = await customerMessage(
                '다른 조건의 상품을 검색하시겠어요?',
                'chatbot'
            );
            setMessages((prev) => [...prev, additionalMessage]);
            setMetaData(null);
            setPackageData([]);
        } else {
            // 패키지 데이터가 있는 경우
            const additionalMessage = await customerMessage(
                '추가로 입력하실 정보를 알려주세요.',
                'chatbot'
            );
            setMessages((prev) => [...prev, additionalMessage]);
        }
    }

    return{
        keywordLogic,
        showPackageLogic,
        userQueryLogic
    }
};

export default useScenario;