import React from "react";
import logo from '../assets/images/golfmon_logo.png';

const Header = () => {
    const handleHome = () => {window.location.href='/';};
    const handleImg = () => {window.location.href='https://m.golfmon.net/#!/home';}

    return (
        <div className="chat-header">
          <img className='logo' src={logo} alt='골프몬 로고' onClick={handleImg}/>
          <button className='home-button' onClick={handleHome}>재시작</button>
        </div>
    );
}

export default Header;